.dropdown-menu {
    width: 200px;
    position: absolute;
    top: 75px;
    right: 10%;
    list-style: none;
    text-align: start;
    z-index: 1;
}

.dropdown-menu li {
    background: #1888ff;
    cursor: pointer;
}

.dropdown-menu li:hover {
    background: #5cabff;
}

.dropdown.menu.clicked {
    display: none;
}

.dropdown-link {
    display: block;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: #fff;
    padding: 16px;
}