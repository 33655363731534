.checkout-container {
    width: 55%;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px auto 0;
  
    .checkout-header {
      width: 100%;
      padding: 10px 0;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid darkgrey;
  
      .header-block {
        text-transform: capitalize;
        width: 23%;
  
        &:last-child {
          width: 8%;
        }
      }
    }
  
    .total {
      margin-top: 30px;
      margin-left: auto;
      font-size: 36px;
    }
}

@media screen and (max-width: 960px) {

  .checkout-container {
    margin-top: 20px;
    width: 100%;

    .checkout-header {
      padding: 0px;

      .header-block {
        width: 25%;
        font-size: 14px;
        
        &:last-child {
          width: 20%;
        }
      }
    }
    
    .total {
      margin-right: 20px;
      margin-top: 20px;
      font-size: 20px;
    }
  }
}