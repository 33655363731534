.navbar-container {

    .navbar {
        height: var(--nav-size);
        background-color: var(--bg);
        padding: 0 1rem;
        padding-right: 40px;
        border-bottom: var(--border);
        border-top: solid 7.5px white;
        display: flex;
        justify-content: flex-end;

        .navbar-nav {
            margin: 0px;
            max-width: 100%;
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            list-style: none;

            .nav-item a {
                text-decoration: none;
                margin: 0 0 0 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
            }
        }
    }
}