.projects-container {
    height: calc(100% - 160px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.projects-navbar-container {
    width: 100%;
}

.resume-header {
    padding: 20px 20px 20px 20px;
    display: flex;
    flex-direction: column;
    max-width: 1000px;
}

.content-header {
    width: 100%;
    background-color: var(--bg);
    padding: 20px 20px 20px 20px;
    color: white;

    p {
        padding-left: 20px;
        max-width: 1000px;
    }

    h2 {
        padding-left: 20px;
    }
}

.content-content {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding: 20px 20px 20px 20px;

    h3 {
        padding-left: 20px;
    }
}

.resume-website {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}

.monsters-rolodex {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.shop-project {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

@media screen and (max-width: 960px) { 
    .monsters-rolodex p {
        padding: 20px;
    }
}