.monsters-app {
    background: linear-gradient(
    to left,
    rgba(7, 27, 82, 1) 0%,
    rgba(0, 128, 128, 1) 100%
    );

    text-align: center;

    .monsters-title {
        font-size: 75px;
        color: #0ccac4;
        font-family: 'Bigelow Rules', cursive;
        margin-top: 0px;
    }

    .card-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

}

@media screen and (max-width: 960px) {
    
}