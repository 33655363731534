footer {
    margin-top: 20px;
    color: white;
    font-weight: bold;
    background: var(--bg);
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}