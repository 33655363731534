.authentication-container {
    display: flex;
    justify-content: space-between;
    width: 900px;
    margin: 30px auto;
}

@media screen and (max-width: 960px) {
    .authentication-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

}