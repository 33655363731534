.sign-up-container {
    display: flex;
    flex-direction: column;
    width: 380px;

    h2 {
        margin: 10px 0;
    }
}

@media screen and (max-width: 960px) {
    .sign-up-container:last-child {
        margin-top: 40px;
    }
}